import { Box, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import 'draft-js/dist/Draft.css';
import { Ref } from 'react';
import { Control, Controller } from 'react-hook-form';
import SearchHighlighting, {
  SearchHighlightingProps,
  SearchHighlightingRef,
} from 'src/components/SearchHighlighting';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { MAIN_COLOR } from 'src/styles/colors';
import 'src/styles/css/hightlight.css';
import { BetMessageForm, RangeError } from '.';
type BetMessageBillBetMessageItemProps = {
  bet?: any;
  control?: Control<BetMessageForm, any>;
  searchMessage?: string;
  rangeErrors?: RangeError;
  slateRef: Ref<SearchHighlightingRef>;
} & Pick<SearchHighlightingProps, 'onChange' | 'onBlur'>;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    marginTop: '5px',
    paddingBottom: '5px',
  },
  txtMessages: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    resize: 'none',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: '10px',
    padding: '10px',
    fontSize: '16px',
    '&:focus': {
      outline: 'none !important',
      border: `1px solid ${MAIN_COLOR}`,
      'box-shadow': `0 0 10px ${MAIN_COLOR}`,
    },
    overflowY: 'auto',
    ...breakpointDown(theme, {
      lg: {
        maxHeight: '42vh',
      },
      sm: {
        maxHeight: '35vh',
      },
      xs: {
        maxHeight: '45vh',
      },
    }),
  },
  searchHighlight: {
    background: 'yellow',
  },
  errorHighlight: {
    background: 'red',
  },
}));

const BetMessageBillBetMessageItem = ({
  control,
  searchMessage,
  rangeErrors,
  slateRef,
  onChange,
  onBlur,
}: BetMessageBillBetMessageItemProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      className={classes.container}
      sx={{
        'div[id=placeholder-3kc8v]': {
          color: 'grey',
          fontStyle: 'italic',
        },
      }}
    >
      <div className={classes.txtMessages}>
        <Controller
          name="message"
          control={control}
          render={({ field }) => {
            return (
              <SearchHighlighting
                ref={slateRef}
                initValueText={field.value ?? ''}
                search={searchMessage}
                errors={rangeErrors}
                onChange={(value) => {
                  field.onChange(value);
                  onChange(value);
                }}
                onBlur={onBlur}
              />
            );
          }}
        />
      </div>
    </Box>
  );
};

export default BetMessageBillBetMessageItem;
