import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { createEditor, Editor, Range as SlateRange, Text, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { CustomElement, FormattedText } from 'src/custom';

import './styles.css';

export type SearchHighlightingRef = {
  replaceEditorValue: (value: string) => void;
};

export type SearchHighlightingProps = {
  search?: string;
  initValueText?: string;
  errors?: {
    error: string;
    start: number;
    end: number;
  };
  onChange?: (value: string) => void;
  onBlur?: () => void;
};

const SearchHighlighting = forwardRef<SearchHighlightingRef, SearchHighlightingProps>(
  function SearchHighlighting({ errors, search, initValueText, onChange, onBlur }, ref) {
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const isInitUpdateRef = useRef(false);

    const replaceEditorValue = useCallback(
      (value: string) => {
        try {
          isInitUpdateRef.current = true;
          // Save the current cursor position
          const { selection } = editor;
          const currentSelection = value !== '' && selection ? { ...selection } : null;

          Transforms.delete(editor, {
            at: {
              anchor: Editor.start(editor, []),
              focus: Editor.end(editor, []),
            },
          });

          Transforms.removeNodes(editor, {
            at: [0],
          });
          value?.split('\n').forEach((item) => {
            Transforms.insertNodes(editor, [
              {
                type: 'paragraph',
                children: [{ text: item }],
              },
            ]);
          });

          // Restore the cursor position only if it's still valid
          if (value !== '' && currentSelection) {
            const newPath = [0];
            const newPosition = Editor.hasPath(editor, newPath)
              ? Editor.start(editor, newPath)
              : null;

            if (newPosition) {
              const leafPath = [0, 0];
              if (Editor.hasPath(editor, leafPath)) {
                Transforms.select(editor, {
                  anchor: { path: leafPath, offset: 0 },
                  focus: { path: leafPath, offset: 0 },
                });
              }
            }
          }
        } catch (error) {
          console.error('Error replacing editor value:', error);
        }
      },
      [editor],
    );

    useImperativeHandle(ref, () => {
      return {
        replaceEditorValue: replaceEditorValue,
      };
    });

    return (
      <Slate
        editor={editor}
        initialValue={[
          {
            type: 'paragraph',
            children: [{ text: initValueText }],
          },
        ]}
        onChange={(value: CustomElement[]) => {
          onChange?.(getSearchValue(value));
        }}
      >
        <Editable
          spellCheck={false}
          className="editable"
          decorate={([node, path]) => {
            if (!Text.isText(node)) {
              return [];
            }

            const ranges: (SlateRange & { errorHighLight?: boolean; highlight?: boolean })[] = [];

            // Highlight search
            const normalizedSearch = search?.normalize('NFC');

            if (normalizedSearch) {
              const start = node.text.normalize('NFC').indexOf(normalizedSearch);

              if (start > -1) {
                ranges.push({
                  anchor: { path: path, offset: start },
                  focus: { path: path, offset: start + normalizedSearch.length },
                  highlight: true,
                });
              }
            }

            // Highlight errors
            const errorTextList = (errors?.error?.split('\n') ?? []).filter((item) => item); // filter empty string

            if (errorTextList.length) {
              const { start: paragraphStart } = getParagraphIndices(editor, node);

              for (const errorText of errorTextList) {
                const start = node.text.substring(errors.start - paragraphStart).indexOf(errorText);

                if (start !== -1) {
                  const anchorOffset = start + (errors.start - paragraphStart);

                  ranges.push({
                    anchor: { path: path, offset: anchorOffset },
                    focus: { path: path, offset: anchorOffset + errorText.length },
                    errorHighLight: true,
                  });
                }
              }
            }

            return ranges;
          }}
          renderLeaf={(props) => <Leaf {...props} />}
          onBlur={onBlur}
        />
      </Slate>
    );
  },
);

const Leaf = ({ attributes, children, leaf }) => {
  return (
    <span
      {...attributes}
      {...(leaf.highlight && { 'data-cy': 'search-highlighted' })}
      style={{
        fontWeight: leaf.bold ? 'bold' : 'normal',
        backgroundColor: leaf.errorHighLight ? 'red' : leaf.highlight ? 'yellow' : 'inherit',
      }}
    >
      <i style={{ userSelect: 'none' }} contentEditable={false}></i>
      {children}
    </span>
  );
};

const getSearchValue = (value: CustomElement[]): string => {
  if (!value || !value.length) {
    return '';
  }
  return value.map((item) => item.children.map((item) => item.text).join(' ')).join('\n');
};

// Function to get the indices of a paragraph node
const getParagraphIndices = (editor: Editor, paragraphNode: FormattedText) => {
  // Join the text content of all text nodes into one full text
  const fullText = getSearchValue(editor.children as CustomElement[]).replaceAll('\\n', '');

  // Get the text of the paragraph node
  const paragraphText = paragraphNode.text;

  // Find the start and end indices of the paragraph in the full text
  const start = fullText.indexOf(paragraphText);
  const end = start + paragraphText?.length;

  return { start: start, end: end };
};

export default SearchHighlighting;
